import React, { Component } from 'react';
import { Box, Card, CardContent, Grid, Button, IconButton } from '@mui/material'
import Typography from '@mui/material/Typography';
import { LoginOutlined } from '@mui/icons-material';

import './Fonts.css'
import './Home.css'
import { useEffect } from 'react';

const Home = () => {

  // Please forgive me for this
  // This code resizes the elements of the AP cards in order to have them all aligned perfectly
  const handleResize = () => {
    var heightsAreEqual = true;
    var apSubtitles = document.getElementsByClassName('aPSubtitle');
    var apDescriptions = document.getElementsByClassName('aPDescription');
    var cardActions = document.getElementsByClassName('cardActions');
    var thisHeight = 0;
    var maxHeight = apSubtitles[0].offsetHeight;
    var heighestIndex = 0;

    // If subtitles are not of equal heights, apply margin to center descriptions
    for (var i = 1; i < apSubtitles.length ; i++)
    {
      thisHeight = apSubtitles[i].offsetHeight;
      if (thisHeight > maxHeight)
      {
        heightsAreEqual = false;
        maxHeight = thisHeight;
        heighestIndex = i;
      }
    }

    if (heightsAreEqual == false)
    {
      for (var i=0; i < apDescriptions.length ; i++)
      {
        // No margin needed for the biggest card
        if (i != heighestIndex)
        {
          apDescriptions[i].style.marginTop = maxHeight+'px';
        }
      }
    }
    else {
      for (var i=0; i < apDescriptions.length ; i++)
      {
        // No margin needed for the biggest card
        if (i != heighestIndex)
        {
          apDescriptions[i].style.marginTop = 0+'px';
        }
      }
    }

    heightsAreEqual = true;
    maxHeight = apDescriptions[0].offsetHeight;
    // If descriptions are not of equal heights, apply margin to center the action buttons
    for (var i=1; i < apDescriptions.length ; i++)
    {
      thisHeight = apDescriptions[i].offsetHeight;
      if (thisHeight > maxHeight)
      {
        heightsAreEqual = false;
        maxHeight = thisHeight;
        heighestIndex = i;
      }
    }

    if (heightsAreEqual == false)
    {
      for (var i=0; i < apDescriptions.length ; i++)
      {
        // No margin needed for the biggest card
        if (i != heighestIndex)
        {
          apDescriptions[i].parentElement.style.marginBottom = maxHeight - apDescriptions[i].offsetHeight+'px';
        }
      }
    }
    else {
      for (var i=0; i < apDescriptions.length ; i++)
      {
        // No margin needed for the biggest card
        if (i != heighestIndex)
        {
          apDescriptions[i].parentElement.style.marginBottom = 0+'px';
        }
      }
    }

    heightsAreEqual = true;
    maxHeight = cardActions[0].offsetHeight;
    // If buttons are not of equal heights, apply margin to center the action buttons
    for (var i=1; i < cardActions.length ; i++)
    {
      thisHeight = cardActions[i].offsetHeight;
      if (thisHeight > maxHeight)
      {
        heightsAreEqual = false;
        maxHeight = thisHeight;
        heighestIndex = i;
      }
    }

    if (heightsAreEqual == false)
    {
      for (var i=0; i < cardActions.length ; i++)
      {
        // No margin needed for the biggest card
        if (i != heighestIndex)
        {
          apDescriptions[i].parentElement.style.marginBottom = apDescriptions[i].parentElement.style.marginBottom + (maxHeight - cardActions[i].offsetHeight)/2+'px';
        }
      }
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize, false);
  }, [])

  return (
    <>
      <Grid container spacing={6} className='cardsContainer'>
        <Grid item lg={6}>
                  <div className="clickableTitle" onClick={() => { window.location.href = 'https://ap-imfinzi-topaz-public-signup.icta.fr/'}}>
            <Box sx={{ minWidth: 150, height:'100%' }}>
              <Card variant="outlined" className="aPCard">
                <CardContent>
                  <Grid container spacing={0} className='titleGrid'>
                    <Grid item xs={11}>
                      <h1 className='aPTitle'>
                        Accès précoce post-AMM
                      </h1>
                    </Grid>
                    <Grid item xs={1}>
                      <LoginOutlined className='loginIcon'/>
                    </Grid>
                  </Grid>
                  <h2 className='aPSubtitle'></h2>
                </CardContent>
                <CardContent>
                  <p className='aPDescription'>Cancer des voies biliaires</p>
                </CardContent>
                <CardContent>
                  <Grid container spacing={8} className='cardActions'>
                    <Grid item xs={3} className='cardAction'>
                                          <Button variant='outlined' size="large" onClick={(event) => { window.open('/documents/AP_IMFINZI_TOPAZ_PUT-RD-AP2.pdf', '_blank'); event.stopPropagation(); }} className='buttonAction buttonText'>PUT-RD</Button>
                    </Grid>
                    <Grid item xs={3} className='cardAction'>
                                          <Button variant='outlined' size="large" onClick={(event) => { window.open('/documents/AP_IMFINZI_TOPAZ_RCP_IMFINZI_V2.PDF', '_blank'); event.stopPropagation(); }} className='buttonAction buttonText'>RCP</Button>
                    </Grid>
                    <Grid item xs={3} className='cardAction'>
                                          <Button variant='outlined' size="large" onClick={(event) => { window.open('/documents/AP_IMFINZI_TOPAZ_Annexe3-PUT-AP2_NoticePatient.pdf', '_blank'); event.stopPropagation(); }} className='buttonAction buttonNormalCase buttonText'>Note d'Information</Button>
                    </Grid>
                    <Grid item xs={3} className='cardAction'>
                                          <Button variant='outlined' size="large" onClick={(event) => { window.open('/documents/AP_IMFINZI_TOPAZ_QLQ-C30 French (Europe) 2.2.pdf', '_blank'); event.stopPropagation(); }} className='buttonAction buttonNormalCase buttonText'>Questionnaire QLQ-C30</Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </div>
        </Grid>
        <Grid item lg={6}>
                  <div className="clickableTitle" onClick={() => { window.location.href = 'https://ap-imfinzi-himalaya-public-signup.icta.fr/' }}>
            <Box sx={{ minWidth: 150, height:'100%' }}>
              <Card variant="outlined" className="aPCard">
                <CardContent>
                  <Grid container spacing={0} className='titleGrid'>
                    <Grid item xs={11}>
                      <h1 className='aPTitle'>
                        Accès précoce post-AMM
                      </h1>
                    </Grid>
                    <Grid item xs={1}>
                      <LoginOutlined className='loginIcon' />
                    </Grid>
                  </Grid>
                  <h2 className='aPSubtitle'>En association avec IMJUDO (trémélimumab)</h2>
                </CardContent>
                <CardContent>
                  <p className='aPDescription'>Carcinome hépatocellulaire (CHC) avancé ou non résécable</p>
                </CardContent>
                <CardContent>
                  <Grid container spacing={6} className='cardActions'>
                    <Grid item xs={2} className='cardAction'>
                                          <Button variant='outlined' size="large" onClick={(event) => { window.open('/documents/put-rd-IMFINZI_IMJUDO_VCellule.pdf', '_blank'); event.stopPropagation(); }} className='buttonAction buttonText'>PUT-RD</Button>
                    </Grid>
                    <Grid item xs={2} className='cardAction'>
                                          <Button variant='outlined' size="large" onClick={(event) => { window.open('/documents/AP IMFINZI IMJUDO_RCP IMFINZI.PDF', '_blank'); event.stopPropagation(); }} className='buttonAction buttonText'>RCP IMFINZI</Button>
                    </Grid>
                    <Grid item xs={2} className='cardAction'>
                                          <Button variant='outlined' size="large" onClick={(event) => { window.open('/documents/AP IMFINZI IMJUDO_RCP IMJUDO.PDF', '_blank'); event.stopPropagation(); }} className='buttonAction buttonText'>RCP IMJUDO</Button>
                    </Grid>
                    <Grid item xs={3} className='cardAction'>
                                          <Button variant='outlined' size="large" onClick={(event) => { window.open('/documents/AP IMFINZI_IMJUDO_Annexe 3_Notice patient.pdf', '_blank'); event.stopPropagation(); }} className='buttonAction buttonNormalCase buttonText'>Note d'Information</Button>
                    </Grid>
                    <Grid item xs={3} className='cardAction'>
                                          <Button variant='outlined' size="large" onClick={(event) => { window.open('/documents/3HI001G_bis_-_carte patient_print_terrain_A4_20032023.pdf', '_blank'); event.stopPropagation(); }} className='buttonAction buttonNormalCase buttonText'>Carte patient IMJUDO</Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default Home;