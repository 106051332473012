import React, { Component } from 'react';
import {  Box, Grid } from "@mui/material"

import './Fonts.css'
import './Header.css'

export class Header extends Component {
  static displayName = Header.name;

  render() {
    return (
      <>
        <Grid container spacing={0} className='headerLogos'>
          <Grid item lg={1} className='ictaBlue'><Box className='ictaLogo'></Box></Grid>
          <Grid item lg={7} className='ictaBanner'></Grid>
          <Grid item lg={2} className='gradientBanner'></Grid>
          <Grid item lg={2} className='ictaWhite'><Box className='sponsorBanner'></Box></Grid>
          <Grid item lg={12}>
            <Box className='ictaDivider'></Box>
          </Grid>
        </Grid>
        <Box className="headerBox">
          <h1>
            Portail Accès Précoces IMFINZI
            <br />
            (durvalumab)
          </h1>
        </Box>
      </>
    )
  }
}