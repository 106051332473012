import React, { Component } from 'react';
import {  Box } from "@mui/material"

import './Fonts.css'
import './Footer.css'

export class Footer extends Component {
  static displayName = Footer.name;

  render() {
    return (
        <Box className="footerBox">
            <p>Les accès aux plateformes des accès précoces liés au produit IMFINZI sont sécurisés et nécessitent des codes de connexion. Cliquez sur le lien suivant pour accéder aux <a target="_blank" href="/documents/Note_d_information_pro_de_sante_PUT-RD.pdf">mentions légales d'AstraZeneca</a>.</p>
        </Box>
    )
  }
}